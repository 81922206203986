
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text'

import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, CardActions, CardContent, CardHeader, Divider, FormControlLabel, FormLabel, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import Animation from "components/animations/Animation"
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import { TenantService } from "services/TenantService"
import { UseActiveUserOrganizationIdStore } from "stores/ActiveUserOrganizationsStore"
import { Bootstrapper } from "Bootstrapper"
import { Context } from "./Context"
import { Card } from "../../models/Card"
import CardDashboard from "components/dashboard/CardDashboard"


const Dashboard = (props: any) => {
    const navigate = useNavigate();
    const userService = new UserService();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const userId = userService.getActiveUserId();
    const context = Bootstrapper.getInstance<Context>(Context);


    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);

    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }



    useEffect(() => {
        loadDataAsync();
    }, []);



    const loadDataAsync= async() => {
        context.setBusyIndicator(true);
        
        context.setBusyIndicator(false);
    }

    const onRunCleanupAsync = async() => {
        context.setBusyIndicator(true);
            await userService.runCleanupAsync()
        context.setBusyIndicator(false);

    }

    const getCards = () => {
        var cards = [];
        cards.push(new Card(
            "Run Cleanup",
            <Box>
                <Text>Clean up old stale data.</Text>
                <Box mt={1}><Button onClick={async() => onRunCleanupAsync()}>Run</Button></Box>
            </Box>,
            "electrical_services"));
        return cards;
    }
    const cards = getCards();


    return(
        <Section disablePadding id="section-plans" bgColor={bg1}>
                <Animation duration="medium" overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div>

                        <Grid container justifyContent='flex-start' alignItems="stretch"spacing={2}>
                                {
                                    cards.map((f, i) => {
                                        return <Grid key={i} item xs={12} md={4}>
                                            <Item sx={{height: '100%'}}>
                                                    <div style={{height: '100%'}}>
                                                        <CardDashboard sx={{bgcolor: ColorsUtil(theme, 'White'), height: "100%"}} card={f}/>
                                                    </div>                                    
                                            </Item>
                                        </Grid>
                                    })
                                }
                            </Grid>

                            <Stack 
                            direction="column"
                            divider={<Divider orientation="horizontal" flexItem />} 
                            spacing={1}>


                        </Stack>   
                    </div>
                </Animation>
        </Section>

    )


}


export default Dashboard