
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import { Bootstrapper } from "Bootstrapper"
import { Context } from "./Context"
import LoadingButton from "components/buttons/LoadingButton"
import { Toast } from "stores/models/Toast"
import { UseToastStore, Update as ToastUpdate } from 'stores/ToastStore';
import Link from "components/buttons/Link"


const Integrations = (props: any) => {
    const navigate = useNavigate();
    const userService = new UserService();
    
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const context = Bootstrapper.getInstance<Context>(Context);
    const userId = userService.getActiveUserId();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [generalValidationMsg, setGeneralValidationMsg] = useState<string>("");
    const [contactValidationMsg, setContactValidationMsg] = useState<string>("");
    const [hasGrantedAccess, setHasGrantedAccess] = useState<boolean>();


    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }


    useEffect(() => {
        loadDataAsync();
    }, []);

    const loadDataAsync= async() => {
        context.setBusyIndicator(true);
        //const user = await userService.getUserAsync(userId);
        const hasGrantedConsent = await userService.hasGrantedDmsAccessAsync();
        setHasGrantedAccess(hasGrantedConsent);
        context.setBusyIndicator(false);
    }

    const requestGraphConsent = async() => {
        const url = await await userService.getDmsConsentLinkAsync();
        window.location.href = url;
    }

    const cancelGraphConsent = async() => {
        await userService.removeDmsAccessAsync();
        const hasAccess = await userService.hasGrantedDmsAccessAsync();
        setHasGrantedAccess(hasAccess);
        ToastUpdate(new Toast(true, "Access canceled"));
    }

    

    return(
        <Container sx={isMobile ? null : {margin: 0}} disableGutters maxWidth="xs">

        <Section disablePadding id="section-plans" bgColor={bg1}>
                <Animation duration="medium" overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div>
                        <Grid container alignItems="stretch"spacing={2}>
                            <Grid flexGrow={1} item sm={12} md={12}>
                                <Item sx={{height: '100%'}}>
                                        <div style={{height: '100%'}}>
                                        <ExpansionPanel expanded={true} title="Microsoft OneDrive">
                                            <Form flat
                                                validationMsg={contactValidationMsg}
                                                >
                                                    <FieldCollection>
                                                        {!hasGrantedAccess && <TextField isReadOnly disabled label="" value="Integrate OneDrive with Zibbit" endAdornment={<Button size="small" onClick={async() => await requestGraphConsent()}>Grant Permission</Button>} />}
                                                        {hasGrantedAccess && <TextField
                                                         isReadOnly 
                                                         disabled 
                                                         label="" 
                                                         value="Zibbit has access"
                                                         helperText={<>Manage privacy and access for your Microsoft Account <Link target='_blank' href="https://account.microsoft.com/privacy/app-access">here</Link></> }
                                                         endAdornment={<Button size="small" onClick={async() => await cancelGraphConsent()}>Cancel Access</Button>}
                                                         />}
                                                    </FieldCollection>
                                            </Form>
  
                                        </ExpansionPanel>
                                        </div>                                    
                                </Item>
                            </Grid>
                        </Grid>
                    </div>
                </Animation>
        </Section>
        </Container>


    )


}


export default Integrations