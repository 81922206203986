
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text'

import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, CardActions, CardContent, CardHeader, Divider, FormControlLabel, FormLabel, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import { TenantService } from "services/TenantService"
import { PermissionTicket } from "models/PermissionTicket"
import { PermissionRequest, UserAccountPermissionRequest } from "models/PermissionRequest"
import { ResourceType } from "models/ResourceType"
import { AppScopes, ScopeUtils } from "models/PermissionScopes"
import { FreeTrialStatus } from "models/FreeTrialStatus"
import Organizations from "./Organizations"
import { Tenant } from "models/Tenant"
import { UseActiveUserOrganizationIdStore } from "stores/ActiveUserOrganizationsStore"
import { Bootstrapper } from "Bootstrapper"
import { Context } from "./Context"
import { Card } from "../../models/Card"
import CardDashboard from "../../components/dashboard/CardDashboard"
import Link from "components/buttons/Link"


const Dashboard = (props: any) => {
    const navigate = useNavigate();
    const userService = new UserService();
    const tenantService = new TenantService();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const userId = userService.getActiveUserId();
    const userOrgs = UseActiveUserOrganizationIdStore();
    const ownerOrgId = tenantService.getOwnerOrganizationId(userOrgs, userId);
    const context = Bootstrapper.getInstance<Context>(Context);


    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [validationMsg, setValidationMsg] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [canSubmitFreeTrial, setCanSubmitFreeTrial] = useState<boolean>(true);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [plan, setPlan] = useState<Plan>();
    const [isMemberOfValidSubscription, setIsMemberOfValidSubscription] = useState<boolean>(false);
    const [freeTrialStatus, setFreeTrialStatus] = useState<FreeTrialStatus>(FreeTrialStatus.Expired);
    const [ownerOrganization, setOwnerOrganization] = useState<Tenant>(null);
    const [canManageBilling, setCanManageBilling] = useState<boolean>(false);

    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    const onSubmitAsync = () => {

    }

    const getPermissionTicketAsync = async(userId: string): Promise<PermissionTicket> => {
        var request = new UserAccountPermissionRequest(ResourceType.UserBilling);
        request.RequestingUserId = userId;
        request.resourceOwnerId = userId;
        return await userService.getPermissionTicketAsync(request) as PermissionTicket;
    }


    useEffect(() => {
        loadDataAsync();
    }, []);



    const loadDataAsync= async() => {
        context.setBusyIndicator(true);
        var user = await userService.getUserAsync(userId);
        var username = user.userName;
        
        var isMemberOfValidSubscription = await userService.hasFeaturesEnabledAsync();
        var freeTrialStatus = await userService.GetFreeTrialStatusAsync();
        var ticket = await getPermissionTicketAsync(userId);
        var canManageBilling =  ScopeUtils.containsScope(ticket, AppScopes.userBilling.Manage);

        
        setIsMemberOfValidSubscription(isMemberOfValidSubscription);
        setFreeTrialStatus(freeTrialStatus);
        setCanManageBilling(ownerOrgId && canManageBilling);    

        if(ownerOrgId){
            var ownerOrganization = await tenantService.getOrganizationById(ownerOrgId);
            setOwnerOrganization(ownerOrganization);
            var plan = await tenantService.getOrganizationPlanAsyn(ownerOrganization.id);
            setPlan(plan);    
        }
        context.setBusyIndicator(false);
    }

    const getStatus = () => {
        if(freeTrialStatus === FreeTrialStatus.Active)
            return 'Enjoy your Free Trial';        
        
        if(isMemberOfValidSubscription)
            return "You have full access";
        
        return 'You have limited access';
    }


    const onBillingClickedAsync = async() => {
        var url = await userService.GetCustomerPortal();
        window.location.href = url;
    }


    const getCards = () => {
        var cards = [];
        const hasAccess = isMemberOfValidSubscription || freeTrialStatus == FreeTrialStatus.Active;
        const statusIcon = hasAccess ? "toggle_on" : "toggle_off";
        const bgColor = hasAccess ? null : "warning";
        const details = hasAccess || ownerOrganization ? getStatus() :
            <Text>
                <span>Use an access key to request access <Link variant="text" component={NavLink} to={'organizations'}>here</Link>. </span>
                {/* <span>or select a plan <Link variant="text" component={NavLink} to={'/plans'}>here</Link>.</span> */}
            </Text>
        cards.push(new Card(
            `Access: ${hasAccess ? 'Full' : 'Limited'}`, 
            details, 
            statusIcon, 
            bgColor, 
            ))

        cards.push(new Card(
            "Plugin", 
            <Text>Need the software? Get it <Link component={NavLink} to="/downloads">here</Link>.</Text>, "electrical_services"));
        if(ownerOrganization){
            cards.push(new Card(
                "Organization", 
                <Text>Review your organization <Link component={NavLink} to={`/organization/${ownerOrganization.id}`}>here</Link></Text>, 
                "business"));
                if(canManageBilling && ownerOrganization.customerId){
                    const c = new Card(
                        `Billing: ${plan?.name}`,
                        <Text>Review billing details <Link onClick={() => onBillingClickedAsync()}>here</Link></Text>, 
                        "paid");    
                    c.subtitle = `Status: ${Plan.getStatusLabel(plan)}`;
                    cards.push(c);
                }

        }
        return cards;
    }
    const cards = getCards();


    return(
        <Section disablePadding id="section-plans" bgColor={bg1}>
                <Animation duration="medium" overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div>

                        <Grid container justifyContent='flex-start' alignItems="stretch"spacing={2}>
                                {
                                    cards.map((f, i) => {
                                        return <Grid key={i} item xs={12} md={4}>
                                            <Item sx={{height: '100%'}}>
                                                    <div style={{height: '100%'}}>
                                                        <CardDashboard sx={{bgcolor: ColorsUtil(theme, 'White'), height: "100%"}} card={f}/>
                                                    </div>                                    
                                            </Item>
                                        </Grid>
                                    })
                                }
                            </Grid>

                        {/* <Paper>
                            <Box padding={2}> */}
                            <Stack 
                            direction="column"
                            divider={<Divider orientation="horizontal" flexItem />} 
                            spacing={1}>


                        {/* {(!isMemberOfValidSubscription && freeTrialStatus == FreeTrialStatus.Available) && <Box>
                            <span>Free trial available </span>
                                <Button disabled={!canSubmit} onClick={() => activateFreeTrial()}>Start Trial</Button>

                        </Box>} */}

                        {/* {freeTrialStatus == FreeTrialStatus.Active && <Box>
                            <span>Enjoy your free trial!</span>

                        </Box>
                        }

                        {freeTrialStatus != FreeTrialStatus.Active && !isMemberOfValidSubscription && <Box>
                            <span>Already have an access key? Submit it </span><Button variant="text" component={Link} to={'organizations'}>here</Button>.
                            <span>Or select a </span><Button variant="text" component={Link} to={'/plans'}>here</Button> to register a new organization. 
                        </Box>
                        }

                        {ownerOrganization && <Box>
                            <span>Review your organization </span>
                                <Button variant="text" component={Link} to={`/organization/${ownerOrganization.id}`}>{ownerOrganization?.name}</Button>
                        </Box>

                        }
                        
                        <Box>
                            <span>Don't have the software? Download it </span> <Button variant="text" component={Link} to={'/downloads'}>here</Button>
                        </Box> */}
                        </Stack>

                        {/* </Box>

                        </Paper> */}


                            
                            {/* 
                            

            
                            </div>
                            <div *ngIf="ownerOrganization">
                                <span class="mr-1">Review your organization</span>
                                <a class="" [routerLink]="['/organization/', ownerOrganization.id]">{{ownerOrganization.name}}</a>
                            </div>
                            <div>
                                <div class="action-wrapper">
                                    <span>Download Zibbit </span><a [routerLink]="['/downloads']">here</a>.
                                </div>
                            </div>
 */}
                    </div>
                </Animation>
        </Section>

    )


}


export default Dashboard