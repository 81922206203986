import Account from "views/account/Account";
import Login from "views/authentication/Login";
import Logout from "views/authentication/Logout";
import Register from "views/authentication/Register";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import Downloads from "views/downloads/Downloads";
import Home from "views/home/Home";
import Plans, { SubscriptionType } from "views/plans/Plans";
import UserProfile from "views/account/Profile";
import OrgProfile from "views/organization/Profile";

import UserOrganizations from "views/account/Organizations";
import Billing from "views/account/Billing";
import Dashboard from "views/account/Dashboard";
import Invitations from "views/organization/Invitations";
import OrgUsers from "views/organization/Users";
import Organization from "views/organization/Organization";
import PasswordResetLink from "views/authentication/PasswordResetLink";
import Admin from "views/admin/Admin";
import AdminDashboard from "views/admin/Dashboard";
import AdminUsers from "views/admin/users/Users";
import AdminInvites from "views/admin/Invitations";
import AdminOrganizations from "views/admin/organizations/Organizations";
import ConfirmEmail from "views/authentication/ConfirmEmail";
import NotFound404 from "views/NotFound404";
import PaymentResult from "views/payment/PaymentResult";
import PrivacyPolicy from "views/policies/PrivacyPolicy";
import TosPolicy from "views/policies/TosPolicy";
import AcceptInvitation from "views/account/AcceptInvitation";
import EulaPolicy from "views/policies/EulaPolicy";
import { UserService } from "services/UserService";
import { ForbiddenError, UnauthorizedError } from "errors/ApplicationErrors";
import PasswordReset from "views/authentication/PasswordReset";
import Demo from "views/demo/Demo";
import ConfirmationLink from "views/user-confirmation/ConfirmationLink";
import About from "views/about/About";
import ContactUs from "views/contact-us/ContactUs";
import DemoConfirm from "views/demo/DemoConfirm";
import GrandfatheredPlans from "views/plans/GrandfatheredPlans";
import SimplePaymentResult from "views/payment/SimplePaymentResult";
import Prerequisites from "views/downloads/Prerequisites";
import Faq from "views/faq/Faq";
import Articles from "views/articles/Articles";
import ExhibitManagementArticle from "views/articles/collection/exhibits/ExhibitManagementArticle";
import IntroArticle from "views/articles/collection/IntroArticle";
import GettingStartedArticle from "views/articles/collection/common/GettingStartedArticle";
import ExhibitSortArticle from "views/articles/collection/exhibits/ExhibitSortArticle";
import ExhibitSyncArticle from "views/articles/collection/exhibits/ExhibitSyncArticle";
import BundleMaterialsArticle from "views/articles/collection/publish/BundleMaterialsArticle";
import PackageMaterialsArticle from "views/articles/collection/publish/PackageMaterialsArticle";
import EmailResetRequest from "views/authentication/reset-email/EmailResetRequest";
import EmailResetRequestConfirm from "views/authentication/reset-email/EmailResetRequestConfirm";
import NewEmailConfirm from "views/authentication/reset-email/NewEmailConfirm";
import Integrations from "views/account/Integrations";
import IntegrationResult from "views/account/IntegrationResult";
// import PublishResult from "views/publish/PublishResult";
const  userService = new UserService();


type ProtectionLevel = "Admin" | "User" | 'Anonymous';



const ProtectedRoutes=(props: any) =>{
  const {level, ...rest} = props;
  const l: ProtectionLevel = level;

  switch(l){
    case 'Admin': {
      if(!userService.isAdmin()){
        return <Navigate to="/" replace />
      }
      break;
    }
    case 'User': {
      if(!userService.isAuthenticated())
        return <Navigate to="/login" replace />
      break;
    }

    case 'Anonymous': {
      if(userService.isAuthenticated())
        return <Navigate to="/account" replace />
      break;
    }
  }
  return <Outlet/>;  
}


const RouteComposition = () => {


    return(
      <Routes>
        <Route element={<ProtectedRoutes level='Anonymous'/>}>
          <Route path='login' element={<Login />} />
          <Route path='register' element={<Register />} />
        </Route>


        <Route element={<ProtectedRoutes level='User'/>}>
          <Route path='logout' element={<Logout />} />
          <Route path='email-confirmation' element={<ConfirmEmail />} />
          <Route path='email-reset'>
              <Route path='request' element={<EmailResetRequest />} />
              <Route path='request-confirmation' element={<EmailResetRequestConfirm />} />
              <Route path='new-email-confirmation' element={<NewEmailConfirm />} />
              <Route index element={<EmailResetRequest />} />
          </Route>
          <Route path='email-reset'>
              <Route path='request' element={<EmailResetRequest />} />
              <Route path='request-confirmation' element={<EmailResetRequestConfirm />} />
              <Route path='new-email-confirmation' element={<NewEmailConfirm />} />
              <Route index element={<EmailResetRequest />} />
          </Route>

          <Route path='account' element={<Account />}>
            <Route path='profile' element={<UserProfile />} />
            <Route path='organizations' element={<UserOrganizations />} />
            <Route path='integrations' element={<Integrations />} />
            {/* <Route path='billing' element={<Billing />} /> */}
            <Route index element={<Dashboard />} />
          </Route>

          <Route path='organization/:id' element={<Organization />}>
            <Route path='profile' element={<OrgProfile />} />
            <Route path='invitations' element={<Invitations />} />
            <Route index element={<OrgUsers />} />
          </Route>
        </Route>

        <Route element={<ProtectedRoutes level='Admin'/>}>
          <Route path='admin' element={<Admin />}>
            <Route path='users' element={<AdminUsers />} />
            <Route path='invites' element={<AdminInvites />} />
            <Route path='organizations' element={<AdminOrganizations />} />
            <Route index element={<AdminDashboard />} />
          </Route>
        </Route>

        <Route path='articles' element={<Articles />}>
          <Route path='getting-started' element={<GettingStartedArticle />} />
          <Route path='exhibit-management' element={<ExhibitManagementArticle />} />
          <Route path='exhibit-sort' element={<ExhibitSortArticle />} />
          <Route path='exhibit-sync' element={<ExhibitSyncArticle />} />
          <Route path='publish-bundle' element={<BundleMaterialsArticle />} />
          <Route path='publish-package' element={<PackageMaterialsArticle />} />
          <Route index element={<IntroArticle />} />
        </Route>

        {/* <Route path='about' element={<About />} /> */}
        <Route path='contact' element={<ContactUs />} />
        <Route path='' element={<Articles />} />
        <Route path='faq' element={<Faq />} />
        <Route path='demo' element={<Demo />} />
        {/* <Route path='demo-confirmed' element={<DemoConfirm />} /> */}
        <Route path='plans'>
          <Route path='firm' element={<Plans type={SubscriptionType.Firm} />} />
          <Route path='grandfathered' element={<GrandfatheredPlans />} />
          <Route index element={<Plans type={SubscriptionType.License} />} />
        </Route>
        <Route path='downloads' >
          <Route path='beta' element={<Downloads beta={true} />} />
          <Route path='prerequisites' element={<Prerequisites />} />
          <Route index element={<Downloads />} />
        </Route>
        <Route path='password-reset' element={<PasswordReset />} />
        <Route path='password-reset-link' element={<PasswordResetLink />} />
        <Route path='payment-success-new' element={<PaymentResult />} />
        <Route path='payment-success' element={<SimplePaymentResult success={true} />} />
        <Route path='payment-cancel' element={<SimplePaymentResult success={false} />} />
        <Route path='accept-user-invitation' element={<AcceptInvitation />} />
        <Route path='privacy' element={<PrivacyPolicy />} />
        <Route path='tos-policy' element={<TosPolicy />} />
        <Route path='eula-policy' element={<EulaPolicy />} />
        <Route path='user-confirmation' element={<ConfirmationLink />} />
        <Route path='integration-success' element={<IntegrationResult success={true} />} />
        <Route path='integration-failed' element={<IntegrationResult success={false} />} />
        {/* <Route path='publish-success' element={<PublishResult success={true} />} />
        <Route path='publish-failed' element={<PublishResult success={false} />} /> */}

        <Route path='home' element={<Home />} />
        <Route index element={<Home />} />
        <Route path='*' element={<NotFound404 />} />
      </Routes>
  )

  }


export default RouteComposition