import { FreeTrialStatus } from './../models/FreeTrialStatus';
import { AppScopes } from './../models/PermissionScopes';
import { PermissionRequest } from './../models/PermissionRequest';
import { PermissionTicket } from './../models/PermissionTicket';
import { User } from './../models/User';
import { CustomerStatus } from './../models/CustomerStatus';
import { TokenUtil } from 'utils/TokenUtil';
import { IdentityClient } from 'api-clients/IdentityClient';
import { PlansClient } from 'api-clients/PlansClient';

import {Card} from '../models/Card';
import {Installer} from '../models/Installer';
import { promises } from 'fs';
import { Benefit } from '../models/Benefit';
import { TutorialStep } from '../models/TutorialStep';
import { Plan } from 'models/Plan';
import { Price } from 'models/Price';
import {UseActiveUserIdStore, Subscription as ActiveUserIdSubscription, Update as UpdateActiveUserId} from 'stores/ActiveUserIdStore';
import {Subscription as OrganizationIdSubscription, Update as UpdateOrganizationId} from 'stores/ActiveUserOrganizationsStore';
import { Bootstrapper } from 'Bootstrapper';
import {singleton} from "tsyringe";
import { LoginDevice } from 'models/LoginDevice';
import { LogoutDevicesSummary } from 'models/LogoutDevicesSummary';

const jwt = require('jsonwebtoken');


@singleton()
export class UserService {
    private tokenUtil: TokenUtil = new TokenUtil();
    private authClient: IdentityClient;
    private plansClient: PlansClient;
    // private get activeUserId(): string {
    //   return this.tokenUtil.getActiveUserId();
    // };
    //private activeUserId = new Subject<string>();
    // public activeUserIdEmitter = this.activeUserId.asObservable();


  
    constructor(authClient: IdentityClient = null, plansClient: PlansClient = null) {
      this.authClient = authClient ?? new IdentityClient();
      this.plansClient = plansClient ?? new PlansClient();
      //this.activeUserId = UseActiveUserIdStoreStore();


    }

    public async runCleanupAsync(): Promise<boolean>{
      var result = await this.authClient.runCleanupStaleDataAsync();
      return result;
    }


    public async getPermissionTicketAsync(request: PermissionRequest): Promise<PermissionTicket>{
      var ticket = await this.authClient.getPermissionTicketAsync(request);
      return ticket;
    }

    public async getUserAsync(userId: string): Promise<User>{
      var users = await this.authClient.getUserAsync(userId);
      return users;
    }

    public async getUsersAsync(): Promise<User[]>{
      var users = await this.authClient.getUsersAsync();
      return users;
    }

    public async getUsersByOrgIdAsync(orgId: string): Promise<User[]>{
      var users = await this.authClient.getUsersByOrgAsync(orgId);
      return users;
    }

    public async confirmAsync(link: string): Promise<string>{
      var result = await this.authClient.userConfirmationAsync(link);
      return result;
    }
    
    public async requestDemoAsync(name: string, email: string): Promise<boolean>{
      var result = await this.authClient.requestDemoAsync(name, email);
      return result;
    }

    public async contactUsAsync(name: string, email: string, msg: string): Promise<boolean>{
      var result = await this.authClient.contactUsAsync(name, email, msg);
      return result;
    }

    public async hasFeaturesEnabledAsync(): Promise<boolean>{
      var result = await this.authClient.hasFeaturesEnabledAsync();
      return result;
    }

    public async ActivateFreeTrialAsync(): Promise<boolean>{
      var result = await this.authClient.activateFreeTrailAsync();
      return result;
    }

    public async extendFreeTrialAsync(userId: string, daysToExtend: number = null): Promise<boolean>{
      var result = await this.authClient.extendFreeTrailAsync(userId, daysToExtend);
      return result;
    }

    public async GetFreeTrialStatusAsync(): Promise<FreeTrialStatus>{
      var result = await this.authClient.getFreeTrailStatusAsync();
      return result;
    }

    public async sendEmailConfirmationLinkAsync(): Promise<boolean>{
      var result = await this.authClient.sendEmailConfirmationLinkAsync();
      return result;
    }

    public async forceResetEmailLinkAsync(userId: string, newEmail: string): Promise<boolean>{
      var result = await this.authClient.forceResetEmailLinkAsync(userId, newEmail);
      return result;
    }

    public async sendEmailResetLinkAsync(newEmail: string): Promise<boolean>{
      var result = await this.authClient.sendEmailUpdateRequestLinkAsync(newEmail);
      return result;
    }

    public async confirmEmailUpdateRequestAsync(token: string, newEmail: string): Promise<boolean>{
      var result = await this.authClient.confirmEmailUpdateRequestAsync(token, newEmail);
      return result;
    }

    public async updateEmailAsync(token: string): Promise<boolean>{
      var result = await this.authClient.updateEmailAsync(token);
      return result;
    }

    public async sendPasswordResetLinkAsync(email: string): Promise<boolean>{
      var result = await this.authClient.sendPasswordResetLinkAsync(email);
      return result;
    }

    public async updatePasswordAsync(userId: string, password: string, token: string, loginEmail: string = null): Promise<boolean>{
      var result = await this.authClient.updatePasswordAsync(userId, password, token)
      if(!loginEmail || !result)      
        return result;
      
      return await this.loginAsync(loginEmail, password);
    }

    public async updateProfileAsync(firstname: string, lastname: string): Promise<boolean>{
      var result = await this.authClient.updateProfileAsync(firstname, lastname);
      return result;
    }

    public async getDmsConsentLinkAsync(): Promise<string>{
      var result = await this.authClient.getDmsConsentLinkAsync();
      return result;
    }

    // public async requestDmsConsentAsync(): Promise<boolean>{
    //   var result = await this.authClient.requestDmsConsentAsync();
    //   return result;
    // }

    public async removeDmsAccessAsync():Promise<boolean>{
      return await this.authClient.deleteOneDriveAccessAsync();
    }

    public async hasGrantedDmsAccessAsync(): Promise<boolean>{
      return await this.authClient.hasGrantedDmsAccessAsync();
    }

    public async registerAdminAsync(username: string, password: string, adminKey: string): Promise<boolean>{
      var result = await this.authClient.registerAdminAsync(username, password, adminKey);
      if(result)
        await this.loginAsync(username, password);
      return result;
    }
    

    public async registerAsync(username: string, password: string, regKey: string): Promise<boolean>{
      var result = await this.authClient.registerAsync(username, password, regKey);
      if(result)
        await this.loginAsync(username, password);
      return result;
    }

    public async deleteAccountAsync(userId: string): Promise<boolean>{
      var result = await this.authClient.deleteAccountAsync(userId);
      if(result && userId == this.getActiveUserId())
        await this.logoutAsync();
      return result;
    }


    public async InviteNewUserAsync(inviteeEmail: string, role: string, trialDays: number = null): Promise<boolean>{
      return await this.authClient.sendNewUserInvitationAsync(inviteeEmail, role, trialDays);
    }

    public async InviteUsersToOrganizationAsync(inviteeEmails: string[], tenantId: string, role: string): Promise<string[]>{
      return await this.authClient.sendTenantMembershipInvitationAsync(inviteeEmails, tenantId, role);
    }

    public async logoutOfDevicesAsync(userId: string, deviceIds: string[], tenantId: string = null): Promise<LogoutDevicesSummary>{
      var result = await this.authClient.logoutOfDevicesAsync(userId, deviceIds, tenantId);
      return result;
    }
    
    public async getLoginDevicesAsync(userId: string, tenantId: string = null): Promise<LoginDevice[]>{
      var result = await this.authClient.getLoginDevicesAsync(userId, tenantId);
      return result;
    }

    public async loginAsync(username: string, password: string, redirectUrl: string = null): Promise<boolean>{
      var authInfo = await this.authClient.getAuthTokenInfoAsync(username, password);
      this.tokenUtil.setAuthInfo(authInfo);
      await this.onLoginAsync();

      return authInfo != null;
    }

    private async onLoginAsync(){
      const userId = this.getActiveUserId();
      UpdateActiveUserId(userId);
      await Bootstrapper.setUserInfoAsync();
    }

    private async onLogoutAsync(){
      UpdateActiveUserId(null);
      await Bootstrapper.setUserInfoAsync();
    }

    public async logoutAsync(): Promise<boolean>{
      var error = null;
      try{
        await this.authClient.logoutAsync();

      }
      catch(err){
        error = err;
      }
      finally{
        this.tokenUtil.setAuthInfo(null);
        this.onLogoutAsync();

        if(error)
          throw error;
        
        return true;
      }

    }

    public async authRefreshAsync(): Promise<boolean>{
      var currentInfo = this.tokenUtil.getAuthInfo();
      this.tokenUtil.setAuthInfo(null); // prevents refresh loop
      var info = await this.authClient.refreshAsync(currentInfo);
      this.tokenUtil.setAuthInfo(info);
      return info != null;
    }

    public async GetCustomerPortal(): Promise<string>{
      var url = await this.plansClient.getCustomerPortalUrlAsync();
      return url;
    }



    public isAuthenticated(): boolean {
      return this.tokenUtil.isAutheticated;
    }

    public getActiveUserId(): string {
      return this.tokenUtil.getActiveUserId();
      // var token = this.tokenUtil.accessToken;
      // if(token == null)
      //   return null;

      // var decoded = jwt.decode(token);
      
      // var userId = decoded.sub;

      // return userId;
    }

    public getActiveUserName(): string {
      var token = this.tokenUtil.accessToken;
      if(token == null)
        return null;

      var decoded = jwt.decode(token);
      var name = decoded.UserName;

      return name;
    }

    public isAdmin(): boolean {
      var token = this.tokenUtil.accessToken;
      if(token == null)
        return null;

      var decoded = jwt.decode(token);
      
      var isAdmin = decoded?.Admin != null;

      return isAdmin;
    }
}