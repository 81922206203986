
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link as NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import { Bootstrapper } from "Bootstrapper"
import Link from "components/buttons/Link"
import LoadingButton from "components/buttons/LoadingButton"
import CircularProgress from "components/progress/CircularProgress"




const ConfirmationLink = (props: any) => {
    const {success, ...rest} = props;
    const navigate = useNavigate();
    const userService = new UserService();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    

    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [isBusy, setIsBusy] = useState<boolean>();
    const [loaded, setLoaded] = useState<boolean>();
    const [searchParams] = useSearchParams();
    const [title, setTitle] = useState<string>();
    const [label, setLabel] = useState<string>();
    const [link, setLink] = useState<string>();


    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    useEffect(() => {
        loadDataAsync();
    }, []);


    const loadDataAsync = async() => {
        const title = searchParams.get("title");
        const label = searchParams.get("label");
        const link = searchParams.get("link");

        setTitle(title);
        setLabel(label);
        setLink(link);
        setLoaded(true);
    }

    const confirm = async() => {
        setIsBusy(true);
        var redirect = await userService.confirmAsync(link);
        setIsBusy(false);
        window.location.href = redirect;
    }

    

    return(
        <Section sx={{flexGrow: 1}} bgColor={bg2}>
            <Container maxWidth="xs">
                
                {loaded && <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div>  
                        <Form 
                            action={<LoadingButton loading={isBusy} onClick={confirm} fullWidth>{label}</LoadingButton>}
                            title={title}>
                        </Form>
                    </div>
                </Animation>}

                {/* {!loaded && <CircularProgress />} */}
                
            </Container>
        </Section>
    )

}


export default ConfirmationLink